<template>

  <v-data-table
    v-bind:class="{
      darkModeClass: $store.state.darkMode == true
    }"
    :headers="headers"
    :items="articles"
    class="dataTable"
    
  >
 
    <template v-slot:header. name="{ header }">
      {{ header.text.toUpperCase() }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="item.fields.title"
        small
        class="mx-3"
        @click="downloadItem(item)"
        target="_blank"
      >
        mdi-download
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { LoadData } from '@/services/LoadData';
import Maps from '@/services/Maps';
export default {
  props: ['activeChart', 'activeAllChart'],
  data: () => ({
    loading: true,
    headers: [
      {
        text: 'Publications scientifiques (titre)',
        align: 'start',
        value: 'fields.title'
      },
      { text: 'Date     ', value: 'fields.publication_date' },
      { text: 'Domaine', value: 'domaine' },
      { text: 'Type', value: `type` },
      { text: 'Télécharger', value: 'actions', sortable: false }
    ],
    articles: []
  }),
  methods: {
    reloadTabWithField() {
      LoadData.loadDataDashBoard(this.nameField).then((dataAfterSearch) => {
        this.articles = dataAfterSearch.dataSearch.records;

        for (var z = 0; z < 20; z++) {
          this.articles[z].type = Maps.mapGender[this.articles[z].fields.genre];
        }
        for (var i = 0; i < 20; i++) {
          this.articles[i].domaine =
            Maps.mapGenderField[this.articles[i].fields.scientific_field];
        }
        this.loading = dataAfterSearch.loadingSearch;
        if (!this.loading) {
          this.$emit('updateLoading', 'tab');
        }
      });
    },
    loadGlobalfield() {
      LoadData.loadDataDashBoard(this.nameField).then((dataS) => {
        this.articles = dataS.dataSearch.records;
        for (var z = 0; z < 20; z++) {
          this.articles[z].type = Maps.mapGender[this.articles[z].fields.genre];
        }
        for (var i = 0; i < 20; i++) {
          this.articles[i].domaine =
            Maps.mapGenderField[this.articles[i].fields.scientific_field];
        }
        this.loading = dataS.loadingSearch;
        if (!this.loading) {
          this.$emit('updateLoading', 'tab');
        }
      });
    },
    downloadItem(item) {
      window.open(
        item.fields.oa_url_for_pdf,
        'Votre document'[item.fields.title]
      );
    }
  },
  computed: {
    nameChartActiveField() {
      return this.$store.state.nameChartActiveField;
    },
    nameField() {
      return this.$store.state.nameField;
    }
  },

  watch: {
    nameField(valeur) {
      if (valeur) {
        this.loading = true;
        this.reloadTabWithField();
        this.$emit('updateLoading', 'tab');
      } else {
        this.loadGlobalfield();
      }
    }
  },
  mounted() {
    if (this.nameField) {
      this.reloadTabWithField();
    } else {
      this.loadGlobalfield();
    }
  }
};
</script>

<style>


.whiteMode {
  background: #ece9e6;
}
th.text-start {
      font-size: .7rem !important;

 }
td.text-start {
    font-size: .7rem !important;
}
.v-data-table__mobile-row__cell{
  font-size: .7rem !important;
}
.v-data-table__mobile-row__header{
  font-size: .7rem !important;
}

</style>
