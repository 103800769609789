import { render, staticRenderFns } from "./SearchView.vue?vue&type=template&id=474655b6&scoped=true&"
import script from "./SearchView.vue?vue&type=script&lang=js&"
export * from "./SearchView.vue?vue&type=script&lang=js&"
import style0 from "../styles/searchView.css?vue&type=style&index=0&id=474655b6&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474655b6",
  null
  
)

export default component.exports