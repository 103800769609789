<template>
  <v-footer class="mb-3" dark padless>
    <v-card
      flat
      tile
      :color="mapFieldColor[nameField]"
      class="lighten-1 white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          icon
          :href="icon.href"
          target="_blank"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0 mb-4">
        La recherche scientifique ouverte est une alternative aux brevets
        privés, en rendant les expériences scientifiques ouvertes à l'examen
        public. L'objectif principal de la science ouverte est d'augmenter la
        transparence et la reproductibilité de la recherche scientifique, ce qui
        peut conduire à de meilleurs résultats pour toutes les personnes
        impliquées, y compris les co-chercheurs. Les avantages de faire de la
        science ouverte sont évidents dans de nombreux domaines. Elle s'est
        révélée prometteuse dans d'autres domaines comme la sociologie..
        <br />
        <br />
        Ce projet reprend par des données concrètes la volonté du Plan National
        pour la Science Ouverte et s'inscrit dans le cadre d'un projet de fin de
        formation developpeur frontend. <br />Ces données se mettent à jour
        automatiquement.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text
        class="white--text mb-4"
        style="margin-bottom: 10px !important"
      >
        ©{{ new Date().getFullYear() }} —
        <strong>FoxbaneProject. All rights reserved.</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
import Maps from '@/services/Maps';
export default {
  data: () => ({
    mapFieldColor: Maps.mapFieldColor,
    icons: [
      {
        icon: 'mdi-linkedin',
        href: 'https://www.linkedin.com/in/chaàbane-lemared2402'
      },
      {
        icon: 'mdi-github-circle',
        href: 'https://github.com/Foxbane-c'
      }
    ]
  }),
  computed: {
    nameField() {
      return this.$store.state.nameField;
    }
  }
};
</script>
<style>
.v-btn {
  text-decoration: none !important;
}

</style>
