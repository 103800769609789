<template>
  <form class="searchBtn" v-if="this.$route.name != 'Search'">
    <label :data-state="state">
      <input
        type="text"
        placeholder="Rechercher un article"
        @click="state = 'open'"
        @blur="state = 'close'"
        v-model="initWordSearch"
        v-on:keydown.enter.prevent="inSearch()"
      />
      <v-icon color="white" size="28">mdi-magnify</v-icon>
    </label>
  </form>
</template>

<script>
import config from '../../config';

export default {
  name: 'Search',
  data() {
    return {
     initWordSearch:"",
      config,
      state: 'close'
    };
  },
  methods: {
    inSearch() {
    this.wordSearch =this.initWordSearch ;
      this.$router.push({ path: '/search' });
    }
  },
  computed:{
     wordSearch: {
      get() {
        return this.$store.state.wordSearch;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadWordSearch', value);
        } else {
          this.$store.commit('loadWordSearch', '');
        }
      }
    },
  }
};
</script>

<style src="./Search.scss" lang="scss">
</style>
