<script>
import { Line } from 'vue-chartjs';
import { LoadData } from '@/services/LoadData';
import Maps from '@/services/Maps';

export default {
  extends: Line,
  props: ['activeChart'],
  data() {
    return {
      loading: true,
      indexLine: '',
      chartData: {
        labels: [],

        datasets: [
          {
            label: '',
            data: [],
            fill: false,
            borderColor: '',
            backgroundColor: '',
            pointBorderColor: '',
            borderWidth: 1
          }
        ]
      },
      options: {
        interaction: {
          intersect: true
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    nameField() {
      return this.$store.state.nameField;
    }
  },
  methods: {
    reloadChartWithField() {
      this.chartData.datasets[0].data = [];
      this.chartData.datasets[0].borderColor = '';
      this.chartData.datasets[0].backgroundColor = '';
      this.chartData.datasets[0].pointBorderColor = '';
      this.chartData.labels = [];
      this.chartData.datasets[0].label = `Nombres de publications dans  ${
        Maps.mapFieldBis[this.nameField]
      } par an`;

      LoadData.loadDataDashBoard(this.nameField).then(dataAfterSearch => {
        this.chartData.datasets[0].borderColor =
          Maps.mapFieldColor[this.nameField];
        this.chartData.datasets[0].backgroundColor =
          Maps.mapFieldColor[this.nameField];
        this.chartData.datasets[0].pointBorderColor =
          Maps.mapFieldColor[this.nameField];

        this.dataAfterSearch = dataAfterSearch.dataSearch;
        for (var z = 0; z < this.dataAfterSearch.facet_groups.length; z++) {
          if (this.dataAfterSearch.facet_groups[z].name == 'publication_date') {
            this.indexLine = z;

            break;
          }
        }
        for (
          var y = 0;
          y < this.dataAfterSearch.facet_groups[this.indexLine].facets.length;
          y++
        ) {
          this.chartData.datasets[0].data.push(
            this.dataAfterSearch.facet_groups[this.indexLine].facets[y].count
          );

          this.chartData.labels.push(
            this.dataAfterSearch.facet_groups[this.indexLine].facets[y].name
          );
          if (window.innerWidth < 500) {
            (this.options.scales.yAxes[0].ticks = {
              fontSize: 10,
              fontFamily: "'Roboto', sans-serif",
              fontColor: '#000'
            }),
              (this.options.scales.xAxes[0].ticks = {
                fontSize: 10,
                fontFamily: "'Roboto', sans-serif",
                fontColor: '#000'
              });
            this.options.legend.labels = { fontSize: 7 };
          }

          this.renderChart(this.chartData, this.options);
        }
        this.loading = dataAfterSearch.loadingSearch;

        if (!this.loading) {
          this.$emit('updateLoading', 'line');
        }
      });
    },
    loadGlobalfield() {
      this.chartData.datasets[0].data = [];
      this.chartData.labels = [];
      this.chartData.datasets[0].borderColor = 'rgb(23,158,184,0.7';
      this.chartData.datasets[0].backgroundColor = 'rgb(23,158,184,0.7';
      this.chartData.datasets[0].pointBorderColor = 'rgb(23,158,184,0.7';
      LoadData.loadDataGlobale().then(dataG => {
        for (var y = 0; y < dataG.data.facet_groups.length; y++) {
          if (dataG.data.facet_groups[y].name == 'publication_date') {
            this.indexLine = y;
            break;
          }
        }
        for (var i = 0; i < dataG.data.facet_groups[0].facets.length; i++) {
          this.chartData.datasets[0].data.push(
            dataG.data.facet_groups[this.indexLine].facets[i].count
          );
          this.chartData.labels.push(
            dataG.data.facet_groups[this.indexLine].facets[i].name
          );
        }
        if (window.innerWidth < 500) {
          (this.options.scales.yAxes[0].ticks = {
            fontSize: 10,
            fontFamily: "'Roboto', sans-serif",
            fontColor: '#000'
          }),
            (this.options.scales.xAxes[0].ticks = {
              fontSize: 10,
              fontFamily: "'Roboto', sans-serif",
              fontColor: '#000'
            });
          this.options.legend.labels = { fontSize: 7 };
        }
        this.loading = dataG.loadingG;
        this.renderChart(this.chartData, this.options);
        if (!this.loading) {
          this.$emit('updateLoading', 'line');
        }
      });
      this.chartData.datasets[0].label =
        'Nombres de publications de la recherche ouverte par an ';
    }
  },

  watch: {
    nameField(valeur) {
      this.loading = true;
      if (valeur) {
        this.reloadChartWithField();
      } else {
        this.loadGlobalfield();
      }
    }
  },
  mounted() {
    if (this.nameField) {
      this.reloadChartWithField();
    } else {
      this.loadGlobalfield();
    }
  }
};
</script>
<style scoped>
.label {
  font-size: 0.1em !important;
}
</style>
