<template>
  <div class="global-component" style="z-index: 999">
    <v-container fluid id="dashboard">
      <div class="Dashboard" id="create">
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <CustomCard>
              <template v-slot:header>
                Ce que l'on entend par la recherche ouverte ...
              </template>
              <template v-slot:content>
                <div class="text-center loading">
                  <p style="text-align: justify">
                    Les publications désignées sur ce site en accès ouvert
                    représentent toutes les publications de recherche mises à
                    disposition librement sur l’internet public. Le taux d'accès
                    ouvert représente le ratio du nombre de publications en accès
                    ouvert rapporté au nombre total de publications sur le même
                    périmètre (par exemple par année, discipline ou éditeur). Deux
                    types d'accès ouvert sont distingués, en fonction du type
                    d'hébergement, chez l'éditeur ou sur archive ouverte.
                    <br /><br />
                    Aujourd'hui, le taux d'accès ouvert est estimé grâce à une
                    méthodologie indépendante et reposant uniquement sur des
                    ressources ouvertes et/ou disponibles publiquement. Le nombre
                    d'articles scientifiques qui concernent la recherche ouverte
                    est de
                    <span>
                      <v-progress-circular
                        v-if="loading == true"
                        :size="15" class="ma-0 pa-0 "
                        :width="1.5"
                        :color="mapFieldColor[nameField]"
                        indeterminate>
                      </v-progress-circular>
                    </span>
                    <span v-if="loading == false" style="font-weight: bold">
                      {{ nOfArticles }}
                    </span>
                    articles publiés.
                  </p>
                </div>
              </template>
            </CustomCard>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12">
            <CustomCard>
              <template v-slot:header>
                Évolution de la recherche publique
              </template>
              <template v-slot:content>
                <div>
                  <div class="text-center">
                    <v-progress-circular
                      v-if="loadingLine == true"
                      class="mt-8"
                      :size="70"
                      :width="7"
                      :color="mapFieldColor[nameField]"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <line-chart
                    @updateLoading="updateLoading($event)"
                    :activeChart="activeChart"
                    v-show="loadingLine == false"
                    style="height: 300px"
                  ></line-chart>
                </div>
              </template>
           </CustomCard>
          </v-col>

          <!-- ---------------- -->
          <v-col cols="12" md="6" sm="12">
            <CustomCard>
              <template v-slot:header>
                Les differents domaines des publications
              </template>
              <template v-slot:content>
                <div>
                  <div class="text-center">
                      <v-progress-circular
                        v-if="loadingPie == true"
                        class="mt-8"
                        :size="70"
                        :width="7"
                        :color="mapFieldColor[nameField]"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <pie-chart
                      @updateLoading="updateLoading($event)"
                      :activeChart="activeChart"
                      v-show="loadingPie == false"
                      style="height: 300px"
                    ></pie-chart>
                </div>
              </template>
            </CustomCard>
          </v-col>
        </v-row>
        <!-- _________________________________________ -->
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12">
            <CustomCard>
              <template v-slot:header>
                Titres des sources de publications les plus importantes
              </template>
              <template v-slot:content>
                <div>
                  <div class="text-center">
                    <v-progress-circular
                      v-if="loadingRadar == true"
                      class="mt-8"
                      :size="70"
                      :width="7"
                      :color="mapFieldColor[nameField]"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <radar-chart
                    v-show="loadingRadar == false"
                    @updateLoading="updateLoading($event)"
                    style="height: 300px"
                  ></radar-chart>
                </div>
              </template>
            </CustomCard>
          </v-col>
          <!-- ------------------- -->
          <v-col cols="12" lg="6" md="6" sm="12">
            <CustomCard>
              <template v-slot:header>
                Les différents genres de publications
              </template>
              <template v-slot:content>
                <div>
                  <div class="text-center">
                    <v-progress-circular
                      v-if="loadingColumn == true"
                      class="mt-8"
                      :size="70"
                      :width="7"
                      :color="mapFieldColor[nameField]"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <column-chart
                    @updateLoading="updateLoading($event)"
                    :activeChart="activeChart"
                    v-show="loadingColumn == false"
                    style="height: 300px"
                  ></column-chart>
                </div>
              </template>
            </CustomCard>
          </v-col>
        </v-row>

        <!-- ---------------- -->
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <CustomCard>
              <template v-slot:header>
                Dernières publications
              </template>
              <template v-slot:content>
                  <TabData
                    :activeChart="activeChart"
                    @updateLoading="updateLoading($event)"
                    ><div class="text-center">
                      <v-progress-circular
                        v-if="loadingTab == true"
                        class="mt-4"
                        :size="70"
                        :width="7"
                        :color="mapFieldColor[nameField]"
                        indeterminate
                      ></v-progress-circular></div
                  ></TabData>
              </template>
            </CustomCard>
          </v-col>
        </v-row>
        <br>
      </div>
    </v-container>
    <v-speed-dial
      fixed
      v-model="fab"
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      :open-on-hover="false"
    >
      <template v-slot:activator>
        <v-btn
          class="principalBtnMenuField"
          :loading="
            loadingColumn ||
            loadingLine ||
            loadingPie ||
            loadingTab ||
            loadingRadar
          "
          v-model="fab"
          dark
          fab
          :color="mapFieldColor[nameField]"
        >
          <v-icon v-if="fab"> mdi-close </v-icon>

          <v-icon v-else> mdi mdi-filter </v-icon>
        </v-btn>
      </template>
      <v-list class="v-list-group" width="auto" v-for="(item, i) in items" :key="i">
        <v-btn
          v-show="
            !loadingColumn &&
            !loadingLine &&
            !loadingPie &&
            !loadingTab &&
            !loadingRadar
          "
          @click="filterFieldMethod(item.field)"
          fab
          dark
          small
          :color="mapFieldColor[item.field]"
          :value="item.field"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small>{{ item.icon }}</v-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </v-btn>
      </v-list>
    </v-speed-dial>
  </div>
</template>

<script>
import { LoadData } from '@/services/LoadData';
import PieChart from '../components/DashBoard/PieChart.vue';
import LineChart from '../components/DashBoard/LineChart.vue';
import ColumnChart from '../components/DashBoard/ColumnChart.vue';
import RadarChart from '@/components/DashBoard/RadarChart.vue';
import TabData from '@/components/DashBoard/TabData.vue';
import CustomCard from '@/components/CustomCard.vue';
import Maps from '@/services/Maps';
import lodash from 'lodash';

export default {
  name: 'vue-globale',
  components: { PieChart, LineChart, ColumnChart, RadarChart, TabData, CustomCard },

  data() {
    return {
      activeChart: '',
      loadingRadar: true,
      loadingLine: true,
      loadingPie: true,
      loadingTab: true,
      loadingColumn: true,
      items: [],
      mapFieldColor: Maps.mapFieldColor,
      mapFieldBis: Maps.mapFieldBis,
      nOfArticles: Number(''),
      loading: true,
      errored: false,
      fab: false
    };
  },

  methods: {
    filterFieldMethod(fieldName) {
      this.nameField = fieldName;
      this.items = [
        {
          title: 'Recherche globale',
          icon: 'mdi mdi-earth',
          field: '',
          to: '/'
        },
        {
          title: 'Recherche médicale',
          icon: 'mdi mdi-medical-bag',
          field: 'Medical+research',
          to: '/'
        },
        {
          title: 'Biologie',
          icon: 'mdi mdi-bio',
          field: 'Biology+(fond.)'
        },
        {
          title: "Climat, Ecologie et l'Energie",
          icon: 'mdi mdi-flower',
          field: 'Earth%2C+Ecology%2C+%0AEnergy+and+applied+biology',
          to: '/'
        },
        {
          title: 'Sciences humaines',
          icon: 'mdi mdi-human',
          field: 'Humanities',
          to: '/'
        },
        {
          title: 'informatique',
          field: 'Computer+and+%0A+information+sciences',
          icon: 'mdi mdi-laptop',
          to: '/'
        },
        {
          title: 'Chimie',
          field: 'Chemistry',
          icon: 'mdi mdi-cup-outline',
          to: '/'
        },
        {
          title: 'Ingénierie',
          field: 'Engineering',
          icon: 'mdi mdi-hammer-wrench',
          to: '/'
        },
        {
          title: 'Mathématique',
          field: 'Mathematics',
          icon: 'mdi mdi-math-compass',
          to: '/'
        },

        {
          title: 'Physique et Astronomie',
          field: 'Physical+sciences,+Astronomy',
          icon: 'mdi mdi-earth',
          to: '/'
        },
        {
          title: 'Sciences sociales',
          field: 'Social+sciences',
          icon: 'mdi mdi-charity',
          to: '/'
        },
        {
          title: 'Autres domaines',
          field: 'unknown',
          icon: 'mdi mdi-plus-box-multiple',
          to: '/'
        }
      ];
      lodash.remove(this.items, (item) => item.field === fieldName);
    },
    updateLoading(value) {
      switch (value) {
        case 'radar':
          this.loadingRadar = false;

          break;
        case 'line':
          this.loadingLine = false;
          break;
        case 'pie':
          this.loadingPie = false;
          break;
        case 'tab':
          this.loadingTab = false;
          break;
        case 'column':
          this.loadingColumn = false;
          break;
        case 'allTrue':
          this.loadingColumn = true;
          this.loadingLine = true;
          this.loadingPie = true;
          this.loadingTab = true;
          this.loadingRadar = true;

          break;
      }
    }
  },

  computed: {
    nameField: {
      get() {
        return this.$store.state.nameField;
      },
      set(value) {
        this.$store.commit('loadFieldName', value);
      }
    }
  },
  watch: {
    nameField() {
      this.updateLoading('allTrue');
    }
  },

  mounted() {
    this.filterFieldMethod(this.nameField);
    LoadData.loadDataGlobale().then((response) => {
      this.nOfArticles = response.data.nhits
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.loading = response.loadingG;
    });
  }
};
</script>

<style src="../styles/vueGlobale.css" scoped></style>
