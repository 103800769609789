<template>
  <div id="app">
    <v-app>
      <navi-bar
        style="z-index: 9 !important"
        v-if="this.$route.name != 'Error'"
        @toggle_dark_mode="toggle_dark_mode"
      ></navi-bar>
      <!-- Sizes your content based upon application components -->
      <div
        class="overflow-y-auto"
        id="scrolling-panel"
        style="max-height: 88vh"
      >
        <v-main fluid :class="{
                'custom-app-background-dark': $store.state.darkMode == true,
                'custom-app-background': $store.state.darkMode == false
              }">
          <!-- Provides the application the proper gutter -->
          <v-container>
            <!-- If using vue-router -->
            <br />
            <router-view></router-view>
          </v-container>
        </v-main>
        <Footer
          style="z-index: 9999 !important "
          v-if="this.$route.name != 'Error'"
        ></Footer>
      </div>
    </v-app>
  </div>
</template>
<script>
import Footer from './components/Footer.vue';
import NaviBar from './components/NaviBar.vue';

export default {
  name: 'app',

  components: {
    NaviBar,
    Footer
  },

  data() {
    return {
      darkMode: false
    };
  },

  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
      this.$store.dispatch('switchModeDark', this.darkMode);

      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem('dark_theme');
    if (theme) {
      if (theme === 'true') {
        this.$vuetify.theme.dark = false;
        this.darkMode = false;
      } else {
        this.$vuetify.theme.dark = false;
        this.darkMode = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
    }
  }
};
</script>
<style>
::-webkit-scrollbar {
  width: .5em;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>
