<template>
  <v-app>
    <v-container fluid class="error-page">
      <v-row class="logo-wrapper">
        <v-img src="@/assets/logo.svg" contain width="62" height="48"></v-img>
        <span class="logo-title"
          >OpenData Dashboard for biological research error !
        </span>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <div class="card">
            <span class="error-logo">404</span>
            <p class="error-text">
              Oops, apparement la page que vous cherchez n'existe pas 😱
            </p>
            <p class="error-subtext">
              Ne vous inquiétez pas, on va vous rediriger vers la page d'acceuil
              en toute securité 😇
            </p>

            <v-btn
              class="text-capitalize"
              dark
              x-large
              color=" #FFBF66"
              @click="redirection"
            >
              Retour à l'acceuil
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import config from '@/config';

export default {
  name: 'Error',
  data() {
    return {
      config
    };
  },
  methods: {
    redirection() {
      this.$router.push({ path: '/' });
    }
  }
};
</script>

<style src="./Error.scss" lang="scss"></style>
