<template>
  <div class="container" style="z-index: 99999 !important">
    <div class="row height d-flex justify-content-center align-items-center">
      <div class="col-md-8">
        <div class="search">
          <i class="fas fa-search"></i>
          <input
            :class="{ 'form-control-DarkMode': $store.state.darkMode == true }"
            v-on:keyup.enter="emitSearchArticle()"
            type="name"
            class="form-control"
            :placeholder="
              'Taper votre mot clé ici 👈 ' + '(' + mapFieldBis[nameField] + ')'
            "
            v-model="wordSearchBis"
          />
          <v-menu offset-y max-height="250">
            <template
              v-slot:activator="{ on, attrs }"
              :color="mapFieldColor[item.field]"
            >
              <v-btn
                style="margin-right: 115px"
                height="3.6em"
                v-bind="attrs"
                v-on="on"
                :color="mapFieldColor[nameField]"
              >
                Filtrer
                <v-icon>mdi mdi-filter </v-icon>
              </v-btn>
            </template>
            <v-list width="auto">
              <p class="ml-2 mb-0 pa-0 title-filter">Par discipline</p>
              <hr />
              <v-radio-group
                height="15px"
                v-for="(item, i) in items"
                :key="i"
                v-model="nameField"
                column
                visible
              >
                <v-radio
                  dense
                  class="ml-2 ma-0"
                  :color="mapFieldColor[item.field]"
                  :value="item.field"
                  :append-icon="item.icon"
                  height="20"
                >
                  <template
                    v-slot:label
                    height="10px"
                    class="pa-0 ma-0 title-filter"
                    dense
                  >
                    <p class="ma-0 pa-0 title-filter">{{ item.title }}</p>
                    <v-icon
                      size="18"
                      class="ml-2 pl-2"
                      :color="mapFieldColor[item.field]"
                      >{{ item.icon }}</v-icon
                    >
                  </template></v-radio
                >
              </v-radio-group>
              <p class="ml-2 mb-0 pa-0 title-filter">
                Par année de publication
              </p>
              <hr />

              <v-radio-group
                height="15px"
                v-for="(yearFilter, z) in arrYearFilter"
                :key="z + items.length"
                v-model="yearFilterActive"
                column
                Forces
                hint
                to
                always
                be
                visible
              >
                <v-radio
                  :color="mapFieldColor[nameField]"
                  dense
                  class="ml-2 ma-0"
                  :value="yearFilter"
                  height="20"
                >
                  <template
                    v-slot:label
                    height="10px"
                    class="pa-0 ma-0 title-filter"
                    dense
                  >
                    <p class="ma-0 pa-0 title-filter">
                      {{ yearFilter }}
                    </p>
                  </template></v-radio
                >
              </v-radio-group>
              <p class="ml-2 mb-0 pa-0 title-filter">Par type de publication</p>
              <hr />

              <v-radio-group
                height="15px"
                v-for="(genreFilter, z) in arrGenreFilter"
                :key="z + items.length + arrYearFilter.length"
                v-model="genreFilterActive"
                column
                Forces
                hint
                to
                always
                be
                visible
              >
                <v-radio
                  :color="mapFieldColor[nameField]"
                  dense
                  class="ml-2 ma-0"
                  :value="genreFilter"
                  height="20"
                >
                  <template
                    v-slot:label
                    height="10px"
                    class="pa-0 ma-0 title-filter"
                    dense
                  >
                    <p class="ma-0 pa-0 title-filter">
                      {{ mapGenderType[genreFilter] }}
                    </p>
                  </template></v-radio
                >
              </v-radio-group>
            </v-list>
          </v-menu>
          <v-btn
            height="3.6em"
            v-on:click="emitSearchArticle()"
            class="search btn px-3"
          >
            Recherche
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';
import Maps from '@/services/Maps';
import { LoadData } from '@/services/LoadData';
export default {
  data() {
    return {
      mapGenderType: Maps.mapGenderType,
      mapFieldColor: Maps.mapFieldColor,
      mapFieldBis: Maps.mapFieldBis,
      indexGenre: '',
      indexYear: '',
      wordSearchBis: '',
      arrYearFilter: [],
      arrGenreFilter: [],
      items: [
        {
          title: 'Recherche globale',
          icon: 'mdi mdi-earth',
          field: ''
        },
        {
          title: 'Recherche médicale',
          icon: 'mdi mdi-medical-bag',

          field: 'Medical+research'
        },
        {
          title: 'Biologie',
          icon: 'mdi mdi-bio',

          field: 'Biology+(fond.)'
        },
        {
          title: "Climat, Ecologie et l'Energie",
          icon: 'mdi mdi-flower',
          field: 'Earth%2C+Ecology%2C+%0AEnergy+and+applied+biology'
        },
        {
          title: 'Sciences humaines',
          icon: 'mdi mdi-human',
          field: 'Humanities'
        },
        {
          title: 'informatique',

          field: 'Computer+and+%0A+information+sciences',
          icon: 'mdi mdi-laptop'
        },
        {
          title: 'Chimie',
          field: 'Chemistry',

          icon: 'mdi mdi-cup-outline'
        },
        {
          title: 'Ingénierie',
          field: 'Engineering',

          icon: 'mdi mdi-hammer-wrench'
        },
        {
          title: 'Mathématique',

          field: 'Mathematics',
          icon: 'mdi mdi-math-compass'
        },

        {
          title: 'Physique et Astronomie',
          field: 'Physical+sciences,+Astronomy',

          icon: 'mdi mdi-earth'
        },
        {
          title: 'Sciences sociales',
          field: 'Social+sciences',

          icon: 'mdi mdi-charity'
        },
        {
          title: 'Autres domaines',
          field: 'unknown',
          icon: 'mdi mdi-plus-box-multiple'
        }
      ]
    };
  },
  methods: {
    emitSearchArticle() {
      this.wordSearch = this.wordSearchBis;
      this.$emit('inResearch');
    }
  },
  computed: {
    nameField: {
      get() {
        return this.$store.state.nameField;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadFieldName', value);
        } else {
          this.$store.commit('loadFieldName', '');
        }
      }
    },
    wordSearch: {
      get() {
        return this.$store.state.wordSearch;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadWordSearch', value);
        } else {
          this.$store.commit('loadWordSearch', '');
        }
      }
    },
    yearFilterActive: {
      get() {
        return this.$store.state.yearFilterActive;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadyearFilterActive', value);
        } else {
          this.$store.commit('loadyearFilterActive', '');
        }
      }
    },
    genreFilterActive: {
      get() {
        return this.$store.state.genreFilterActive;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadGenreFilterActive', value);
        } else {
          this.$store.commit('loadGenreFilterActive', '');
        }
      }
    }
  },

  mounted() {
    this.inputData = this.searchInputS;
    this.yearFilterActive = 'Toutes les années';
    this.arrYearFilter = ['Toutes les années'];
    this.genreFilterActive = 'Tous les types';
    this.arrGenreFilter = ['Tous les types'];

    LoadData.loadDataDashBoard(this.nameField).then((dataAfterSearch) => {
      this.dataFilter = dataAfterSearch.dataSearch;
      for (var z = 0; z < this.dataFilter.facet_groups.length; z++) {
        if (this.dataFilter.facet_groups[z].name == 'publication_date') {
          this.indexYear = z;
         
        }

        if (this.dataFilter.facet_groups[z].name == 'genre') {
          this.indexGenre = z;
        }
      }

      for (
        var y = this.dataFilter.facet_groups[this.indexYear].facets.length - 1;
        y >= 0;
        y--
      ) {
        this.arrYearFilter.push(
          this.dataFilter.facet_groups[this.indexYear].facets[y].name
        );
      }

      for (
        var i = 0;
        i < this.dataFilter.facet_groups[this.indexGenre].facets.length;
        i++
      ) {
        this.arrGenreFilter.push(
          this.dataFilter.facet_groups[this.indexGenre].facets[i].name
        );
      }
    });
  }
};
</script>

<style src="../styles/searchMain.css" scoped />
