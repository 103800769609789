<template>
  <div class="main-container">
    <search-main
      style="z-index: 99999 !important"
      :class="{ 'search-main-itit': !wordSearch }"
      v-on:inResearch="inResearch()"
    ></search-main>
    <Animation v-if="!results && !wordSearch && !Noresults"></Animation>
    <div
    v-bind:class="{ darkModeClass: $store.state.darkMode == true }"
      v-if="results !== 0 && !loading && !error"
      class="
        row row-cols-12
        mt-1
        p-3
        g-3
        container-fluid
        topDiv
        float-start
        bg-trasparent
        my-2
      "
    >
      <h5 class="mb-6 pb-6 container-fluid">
        Nous avons trouvé :
        <span style="font-weight: bold">{{
          this.results.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }}</span>
        publications correspondant au terme "{{ wordSearch }}"
        <span v-if="nameField"> dans {{ mapFieldBis[nameField] }} </span>
        <span v-if="yearFilterActive != 'Toutes les années'">
          au cours de l'année {{ yearFilterActive }}
        </span>
        <span v-if="genreFilterActive != 'Tous les types'">
          de type {{ mapGenderType[genreFilterActive] }}
        </span>
      </h5>
    </div>
    <div
      v-if="Noresults"
      v-bind:class="{ darkModeClass: $store.state.darkMode == true }"
      class="
        row row-cols-12
        mt-2
        g-3
        container-fluid
        float-start
        bg-trasparent bg-trasparent
      "
    >
      <h5 class="m-2 mb-8 mt-9 topDiv results">
        Aucune publication scientifique ne correspond au terme "{{
          wordSearch
        }}"
        <span v-if="nameField"> dans {{ mapFieldBis[nameField] }} </span>
        <span v-if="yearFilterActive != 'Toutes les années'">
          au cours de l'année {{ yearFilterActive }}
        </span>
        <span v-if="genreFilterActive != 'Tous les types'">
          de type {{ mapGenderType[genreFilterActive] }}
        </span>
        <ul v-if="!regex" class="mx-4 my-6 topDiv results">
          Suggestions :
          <li class="my-4">Préférez l'anglais au français.</li>
          <li class="my-4">Essayez d'autres mots.</li>
          <li class="my-4">Utilisez des mots clés plus généraux.</li>
          <li class="my-4">Spécifiez un moins grand nombre de mots.</li>
        </ul>
        <ul v-if="regex" class="mx-4 my-6 topDiv results">
          Suggestions :
          <li class="my-4">Retirez le caractère spécial et réessayez.</li>
        </ul>
      </h5>
    </div>

    <div class="text-center mt-10">
      <v-progress-circular
        v-if="loading"
        class="mt-8 pt-8"
        :size="70"
        :width="7"
        :color="mapFieldColor[nameField]"
        indeterminate
      ></v-progress-circular>
    </div>
    <div
      class="container-fluid-auto bg-trasparent m-4 p-2"
      style="position: relative"
    >
      <div
        class="
          row row-cols-1 row-cols-sm-2 row-cols-xl-6 row-cols-md-3 row-cols-lg-4
          g-1
          publications-container
        "
      >
        <div
          class="col-12 col-xl-2 col-sm-6 col-md-4 col-lg-3"
          v-for="(publication, i) in publications"
          :key="i"
        >
          <CustomCard
            class="card"
            v-bind:class="{ darkModeClass: $store.state.darkMode == true }"
          >
            <template v-slot:header>
              <v-chip
                small
                class="float-startl"
                :color="mapGenderFieldColor[publication.scientific_field]"
              >
                {{ mapGenderField[publication.scientific_field] }}
              </v-chip>
            </template>
            <template v-slot:actions>
              {{ publication.date }}
            </template>
            <template v-slot:content>
              <div>
                <h5 class="title-open" @click="openArticle(publication.open)">
                  {{ publication.title }}
                </h5>
                <p class="card-subtitle mb-1 text-muted">
                  {{ 'Source: ' + publication.source_title }}
                </p>
              </div>
            </template>
            <template v-slot:footer>
              <div>
                <p>{{ publication.genre }}</p>
              </div>
              <el-button
                type="primary"
                plain
                circle
                icon="el-icon-download"
                v-if="publication.download"
                @click="downloadPdf(publication)"
              >
              </el-button>
            </template>
          </CustomCard>
        </div>
      </div>
    </div>

    <div
      class="text-center pagMediaQuery"
      v-if="results !== 0 && !loading && !error"
    >
      <v-pagination
        class="pt-4"
        v-model="pagination.page"
        :length="pagination.total"
        :total-visible="20"
        @input="inResearch(true)"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { LoadData } from '@/services/LoadData';
import SearchMain from '../components/SearchMain.vue';
import CustomCard from '../components/CustomCard.vue';
import Animation from '../components/Animation.vue';
import Maps from '@/services/Maps';
export default {
  components: { SearchMain, CustomCard, Animation },
  data() {
    return {
      mapFieldColor: Maps.mapFieldColor,
      mapFieldBis: Maps.mapFieldBis,
      mapField: Maps.mapField,
      mapGenderType: Maps.mapGender,
      mapGenderFieldColor: Maps.mapGenderFieldColor,
      mapGenderField: Maps.mapGenderField,
      loading: true,
      error: false,
      Noresults: false,
      results: 0,
      loadingSearch: false,
      publications: [],
      pagination: {
        page: 1,
        total: 0,
        perPage: 8,
        visible: 7
      },
      regex: false
    };
  },
  methods: {
    openSuccess() {
      this.$message({
        showClose: true,
        message: `${this.results
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} publications trouvées dans ${
          this.mapFieldBis[this.nameField]
        }`,
        type: 'success'
      });
    },
    openNoResults() {
      this.$message({
        showClose: true,
        message: `Aucun resultat ne correspond à votre recherche`,
        type: 'danger'
      });
    },

    openWarning() {
      this.$message({
        showClose: true,
        title: 'Attention',
        type: 'warning',
        message:
          "Votre recherche retourne trop d'élément (limitation API). Seuls les 500 premiéres pages de resultats sont affichées"
      });
    },

    inResearch(paginationClick) {
      this.publications = [];
      this.results = 0;
      this.Noresults = false;
      this.regex = false;
      if (!/^[A-Za-z *]+$/g.test(this.wordSearch) && this.wordSearch) {
        this.Noresults = true;
        this.regex = true;
        return;
      }
      if (this.wordSearch) {
        this.loading = true;

        LoadData.loadDataSearchview(
          this.wordSearch,
          this.pagination.page,
          this.nameField,
          this.yearFilterActive,
          this.genreFilterActive
        ).then(dataAfterSearch => {
          this.loading = dataAfterSearch.loadingSearch;
          this.error = dataAfterSearch.errored;
          this.results = dataAfterSearch.dataSearch.nhits;

          for (var i = 0; i < dataAfterSearch.dataSearch.records.length; i++) {
            this.publications.push({
              title: dataAfterSearch.dataSearch.records[i].fields.title,
              date: dataAfterSearch.dataSearch.records[i].fields.year,
              genre: this.mapGenderType[
                dataAfterSearch.dataSearch.records[i].fields.genre
              ],
              scientific_field:
                dataAfterSearch.dataSearch.records[i].fields.scientific_field,
              source_title:
                dataAfterSearch.dataSearch.records[i].fields.source_title,
              download:
                dataAfterSearch.dataSearch.records[i].fields.oa_url_for_pdf,

              open: dataAfterSearch.dataSearch.records[i].fields.doi_url
            });
          }

          this.Noresults =
            dataAfterSearch.dataSearch.nhits === 0 ? true : false;
          if (this.Noresults) {
            this.openNoResults();
          }
          this.loading = dataAfterSearch.loadingSearch;
          this.error = dataAfterSearch.errored;

          this.pagesNav = dataAfterSearch.dataSearch.nhits / 20;

          // définir le max de pages en integrant la restriction API ( >10 000publications )

          if (this.pagesNav > 500) {
            this.pagination.total = 500;
            if (!paginationClick) {
              this.openWarning();
            }
          } else {
            this.pagination.total = Math.round(this.pagesNav);
            if (this.nameField && !this.Noresults && !paginationClick) {
              this.openSuccess();
            }
          }
        });
      }
    },
    downloadPdf(publication) {
      window.open(
        publication.download,
        'Votre document PDF',
        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
      );
    },
    openArticle(openvalue) {
      window.open(
        openvalue,
        'Votre document',
        'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
      );
    }
  },

  computed: {
    wordSearch: {
      get() {
        return this.$store.state.wordSearch;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadWordSearch', value);
        } else {
          this.$store.commit('loadWordSearch', '');
        }
      }
    },

    darkMode() {
      return this.$store.state.darkMode;
    },
    nameField() {
      return this.$store.state.nameField;
    },
    yearFilterActive: {
      get() {
        return this.$store.state.yearFilterActive;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadyearFilterActive', value);
        } else {
          this.$store.commit('loadyearFilterActive', '');
        }
      }
    },
    genreFilterActive: {
      get() {
        return this.$store.state.genreFilterActive;
      },
      set(value) {
        if (value) {
          this.$store.commit('loadGenreFilterActive', value);
        } else {
          this.$store.commit('loadGenreFilterActive', '');
        }
      }
    }
  },
  watch: {
    nameField() {
      if (this.wordSearch) {
        this.pagination.page = 1;
        this.inResearch();
      }
    },
    yearFilterActive() {
      if (this.wordSearch) {
        this.pagination.page = 1;
        this.inResearch();
      }
    },
    genreFilterActive() {
      if (this.wordSearch) {
        this.pagination.page = 1;
        this.inResearch();
      }
    }
  },
  mounted() {
    this.loading = false;
    if (this.wordSearch) {
      this.inResearch();
    }
  }
};
</script>
<style src="../styles/searchView.css" lang="css" scoped></style>
