<script>
import { Radar } from 'vue-chartjs';
import { LoadData } from '@/services/LoadData';
import Maps from '@/services/Maps';

export default {
  extends: Radar,
  props: ['activeChart'],
  data() {
    return {
      loading: true,
      indexRadar: '',
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            borderWidth: 1,
            backgroundColor: 'rgb(178,34,34, 0.7)',

            data: [],
            fill: true
          }
        ]
      },
      options: {
        elements: {
          line: {
            borderWidth: 2
          }
        },
        plugins: {
          display: false
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  methods: {
    reloadChartWithField() {
      this.chartData.datasets[0].data = [];

      this.chartData.labels = [];

      LoadData.loadDataDashBoard(this.nameField).then((dataAfterSearch) => {
        this.dataAfterSearch = dataAfterSearch.dataSearch;
        this.chartData.datasets[0].backgroundColor =
          Maps.mapFieldColor[this.nameField];

        for (var i = 0; i < 7; i++) {
          this.chartData.datasets[0].data.push(
            this.dataAfterSearch.facet_groups[5].facets[i].count
          );
          if (window.innerWidth < 500) {
            this.chartData.labels.push(
              '«' +
                this.dataAfterSearch.facet_groups[5].facets[i].name.substring(
                  0,
                  8
                ) +
                '...»'
            );
          } else {
            if (
              this.dataAfterSearch.facet_groups[5].facets[i].name.length < 40
            ) {
              this.chartData.labels.push(
                '«' + this.dataAfterSearch.facet_groups[5].facets[i].name + '»'
              );
            } else {
              this.chartData.labels.push(
                '«' +
                  this.dataAfterSearch.facet_groups[5].facets[i].name.substring(
                    0,
                    40
                  ) +
                  '...»'
              );
            }
          }

          this.renderChart(this.chartData, this.options);
        }
        this.loading = dataAfterSearch.loadingSearch;
        if (!this.loading) {
          this.$emit('updateLoading', 'radar');
        }
      });
    },
    loadGlobalfield() {
      this.chartData.datasets[0].data = [];
      (this.chartData.datasets[0].backgroundColor = 'rgb(23,158,184,0.7'),
        (this.chartData.labels = []);

      LoadData.loadDataGlobale().then((dataG) => {
        for (var i = 0; i < dataG.data.facet_groups.length; i++) {
          if (dataG.data.facet_groups[i].name == 'publisher') {
            this.indexRadar = i;
            break;
          }
        }
        for (var y = 0; y < 7; y++) {
          this.chartData.datasets[0].data.push(
            dataG.data.facet_groups[5].facets[y].count
          );

          if (window.innerWidth < 500) {
            this.chartData.labels.push(
              '«' +
                dataG.data.facet_groups[5].facets[y].name.substring(0, 8) +
                '...»'
            );
          } else {
            if (dataG.data.facet_groups[5].facets[y].name.length < 40) {
              this.chartData.labels.push(
                '«' + dataG.data.facet_groups[5].facets[y].name + '»'
              );
            } else {
              this.chartData.labels.push(
                '«' +
                  dataG.data.facet_groups[5].facets[y].name.substring(0, 40) +
                  '...»'
              );
            }
          }
          this.renderChart(this.chartData, this.options);
        }
        this.loading = dataG.loadingG;
        if (!this.loading) {
          this.$emit('updateLoading', 'radar');
        }
      });
    }
  },
  computed: {
    nameField() {
      return this.$store.state.nameField;
    }
  },

  watch: {
    nameField(valeur) {
      this.loading = true;
      if (valeur) {
        this.reloadChartWithField();
      } else {
        this.loadGlobalfield();
      }
    }
  },
  mounted() {
    if (this.nameField) {
      this.reloadChartWithField();
    } else {
      this.loadGlobalfield();
    }
  }
};
</script>
<style scoped>
* {
  font-size: 0.3em !important;
}
@media (max-width: 1020px) {
  * {
    font-size: 5px !important;
  }
}
</style>
