<template>
  <v-app-bar
    :color="mapFieldColor[nameField]"
    dark
    shrink-on-scroll
    prominent
    :src="mapFieldCoverColor[nameField]"
    fade-img-on-scroll
    scroll-target="#scrolling-panel"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(100,115,201,.3), rgba(25,32,72,0.3)"
      ></v-img>
    </template>

    <v-app-bar-title style v-if="!nameField">
      OpenData Dashboard for global research
    </v-app-bar-title>
    <v-app-bar-title visibility:visible v-if="nameField">
      Filtre dans {{ mapFieldBis[nameField] }}
    </v-app-bar-title>

    <v-spacer></v-spacer>
    <!-- ---- filtre icon  -->

    <Search />
    <v-btn icon @click="toggle_dark_mode">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>

    <template v-slot:extension>
      <v-tabs align-with-title>
        <v-tab to="/"
          ><v-icon class="px-2">mdi mdi-view-dashboard</v-icon> Vue
          globale</v-tab
        >

        <v-tab to="/search"
          ><v-icon class="px-2">mdi mdi-file-find</v-icon>Recherche de
          publication</v-tab
        >
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import Search from './Search/Search.vue';
import Maps from '@/services/Maps';
export default {
  name: 'naviBar',
  components: { Search },
  data() {
    return {
      mapFieldCoverColor: Maps.mapFieldCoverColor,
      mapFieldColor: Maps.mapFieldColor,
      mapFieldBis: Maps.mapFieldBis
    };
  },
  computed: {
    nameField() {
      return this.$store.state.nameField;
    }
  },
  methods: {
    toggle_dark_mode() {
      this.$emit('toggle_dark_mode');
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.v-image {
  background-position: center;
}
.v-app-bar-title{
  font-family: "Lato";
  font-size: 1em !important;

}
.v-tab {
  font-family: "Lato";
  font-size: 0.5em !important;
  padding: .2em;
 
}
.v-tab--active{
  text-decoration:none !important
}
@media (max-width: 800px) {
  .v-app-bar-title {
    font-size: 0.75em !important;
    word-break: break-word !important;
  }
}
</style>
