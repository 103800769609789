export default {
  mapFieldColor: {
    '': 'rgb(23,158,184,0.7)',
    'Medical+research': 'rgb(150,66,167,0.7)',
    'Biology+(fond.)': 'rgb(114,178,234, 0.7)',
    'Physical+sciences,+Astronomy': 'rgb(253,218,82, 0.7)',
    'Earth%2C+Ecology%2C+%0AEnergy+and+applied+biology': 'rgb(124,184,68, 0.7)',
    Humanities: 'rgb(111,75,15, 0.7)',
    'Computer+and+%0A+information+sciences': 'rgb(67,63,101,0.7)',
    'Social+sciences': 'rgb(43,133,182, 0.7)',
    Chemistry: 'rgb(177,125,50, 0.7)',
    Engineering: 'rgb(233,130,5, 0.7)',
    Mathematics: 'rgb(147,177,208, 0.7)',
    unknown: 'rgb(251,130,92, 0.7)'
  },
  mapGenderFieldColor: {
    'Medical research': 'rgb(150,66,167,0.7)',
    'Biology (fond.)': 'rgb(114,178,234, 0.7)',
    'Physical sciences, Astronomy': 'rgb(253,218,82, 0.7)',
    'Earth, Ecology, \nEnergy and applied biology': 'rgb(124,184,68, 0.7)',
    Humanities: 'rgb(111,75,15, 0.7)',
    'Computer and \n information sciences': 'rgb(67,63,101,0.7)',
    'Social sciences': 'rgb(43,133,182, 0.7)',
    Chemistry: 'rgb(177,125,50, 0.7)',
    Engineering: 'rgb(233,130,5, 0.7)',
    Mathematics: 'rgb(147,177,208, 0.7)',
    unknown: 'rgb(251,130,92, 0.7)'
  },

  mapFieldBis: {
    '': 'La recherche globale',
    'Medical+research': 'le domaine de la recherche médicale',
    'Biology+(fond.)': 'le domaine de la biologie',
    'Physical+sciences,+Astronomy': 'le domaine de la physique et astronomie',
    'Earth%2C+Ecology%2C+%0AEnergy+and+applied+biology':
      "le domaine du climat,écologie et l'énergie",
    Humanities: 'le domaine des sciences humaines',
    'Computer+and+%0A+information+sciences': "le domaine de l'informatique",
    'Social+sciences': 'le domaine des sciences sociales',
    Chemistry: 'le domaine de la chimie',
    Engineering: "le domaine de l'ingénierie",
    Mathematics: 'le domaine des mathématiques',
    unknown: 'les autres domaines'
  },
  mapGenderType: {
    'Tous les types': 'Tous les types',
    'journal-article': 'Article de revue scientifique',
    'book-chapter': 'Chapitre de livre',
    'proceedings-article': 'Article de procédure',
    other: 'Autre source',
    'book / monograph': 'Livre / Monographie'
  },
  mapGender: {
    'journal-article': 'Article de revue scientifique',
    'book-chapter': 'Chapitre de livre',
    'proceedings-article': 'Article de procédure',
    other: 'Autre source',
    'book / monograph': 'Livre / Monographie'
  },
  mapGenderField: {
    'Medical research': 'Recherche médicale',
    'Biology (fond.)': 'Biologie',
    'Physical sciences, Astronomy': 'Physique et Astronomie',
    'Earth, Ecology, \nEnergy and applied biology':
      "Climat, Ecologie et l'Energie",
    Humanities: 'Sciences humaines',
    'Computer and \n information sciences': 'informatique',
    'Social sciences': 'Sciences sociales',
    Chemistry: 'Chimie',
    Engineering: 'Ingénierie',
    Mathematics: 'Mathématique',
    unknown: 'Autres domaines'
  },

  mapField: {
    'Medical research': 'le domaine Recherche médicale',
    'Biology (fond.)': 'Biologie',
    'Physical sciences, Astronomy': 'Physique et Astronomie',
    'Earth, Ecology, \nEnergy and applied biology':
      "Climat, Ecologie et l'Energie",
    Humanities: 'Sciences humaines',
    'Computer and \n information sciences': 'informatique',
    'Social sciences': 'Sciences sociales',
    Chemistry: 'Chimie',
    Engineering: 'Ingénierie',
    Mathematics: 'Mathématique',
    unknown: 'Autres domaines'
  },
  mapFieldCoverColor: {
    '':
      'https://cdn.pixabay.com/photo/2016/11/09/15/27/dna-1811955_960_720.jpg',
    'Medical+research':
      'https://cdn.pixabay.com/photo/2018/11/20/16/43/laboratory-3827735_960_720.jpg',
    'Biology+(fond.)':
      'https://cdn.pixabay.com/photo/2019/08/13/08/15/analysis-4402809_960_720.jpg',
    'Physical+sciences,+Astronomy':
      'https://cdn.pixabay.com/photo/2018/08/15/13/10/galaxy-3608029_1280.jpg',
    'Earth%2C+Ecology%2C+%0AEnergy+and+applied+biology':
      'https://cdn.pixabay.com/photo/2014/04/17/23/26/environmental-protection-326923_960_720.jpg',
    Humanities:
      'https://cdn.pixabay.com/photo/2016/01/21/23/04/child-1154951_960_720.jpg',
    'Computer+and+%0A+information+sciences':
      'https://cdn.pixabay.com/photo/2015/05/26/23/52/technology-785742_960_720.jpg',
    'Social+sciences':
      'https://cdn.pixabay.com/photo/2016/12/14/09/35/online-1905876_960_720.jpg',
    Chemistry:
      'https://cdn.pixabay.com/photo/2019/01/29/16/51/periodic-system-3962844_960_720.jpg',
    Engineering:
      'https://cdn.pixabay.com/photo/2013/11/27/16/01/sunset-219354_1280.jpg',
    Mathematics:
      'https://cdn.pixabay.com/photo/2019/04/14/10/27/book-4126483_960_720.jpg',
    unknown:
      'https://cdn.pixabay.com/photo/2016/11/30/12/16/question-mark-1872665_960_720.jpg'
  }
};
