// import Data API from data.enseignementsup-recherche.gouv.fr
import axios from 'axios';

export let LoadData = {
  // fonction alimente les données globales dans le composant ( GlobalSearch)
  async loadDataGlobale() {
    let data, errored, loadingG;
    await axios

      .get(
        'https://data.enseignementsup-recherche.gouv.fr/api/records/1.0/search/?dataset=open-access-monitor-france&q=&rows=1&facet=publication_date&facet=genre&facet=is_oa&facet=oa_host_type&facet=year&facet=publisher&facet=source_title&facet=repositories&facet=scientific_field&facet=oa_host_type_year&facet=oa_host_type_year_scientific_field'
      )
      .then(response => {
        data = response.data;
      })
      .catch(error => {
        console.log(error);
        errored = true;
      })
      .finally(() => (loadingG = false));
 

    return { data, errored, loadingG };
  },

  // fonction qui conditionne les données pour  le Dashboard
  async loadDataDashBoard(nameField) {
    let dataSearch, errored, loadingSearch;
    let redactionField = '';
    if (nameField) {
      redactionField = `&refine.scientific_field=${nameField}`;
    }
    await axios
      .get(
        `https://data.enseignementsup-recherche.gouv.fr/api/records/1.0/search/?dataset=open-access-monitor-france&rows=20&start=0&facet=publication_date&facet=genre&facet=is_oa&facet=oa_host_type&facet=year&facet=publisher&facet=source_title&facet=repositories&facet=scientific_field&facet=oa_host_type_year&facet=oa_host_type_year_scientific_field${redactionField}`
      )
      .then(response => {
        dataSearch = response.data;
      })
      .catch(error => {
        console.log(error);
        errored = true;
      })
      .finally(() => (loadingSearch = false));

    return { dataSearch, errored, loadingSearch };
  },

  // fonction qui conditionne les données pour  le composants searchView
  async loadDataSearchview(
    wordSearch,
    pageNumber,
    nameField,
    yearFilterActive,
    publicationType
  ) {
    let dataSearch, errored, loadingSearch;
    let start = (pageNumber - 1) * 20;
    let redactionField = '';
    if (nameField) {
      redactionField = `&refine.scientific_field=${nameField}`;
    }

    if (yearFilterActive != 'Toutes les années') {
      let redacYearFilterActive = `&refine.publication_date=${yearFilterActive}`;

      redactionField += redacYearFilterActive;
    }
    if (publicationType != 'Tous les types') {
      let redacPublicationTypeFilterActive = `&refine.genre=${publicationType}`;

      redactionField += redacPublicationTypeFilterActive;
    }

    await axios
      .get(
        `https://data.enseignementsup-recherche.gouv.fr/api/records/1.0/search/?dataset=open-access-monitor-france&q=${wordSearch}&rows=20&start=${start}&facet=publication_date&facet=genre&facet=is_oa&facet=oa_host_type&facet=year&facet=publisher&facet=source_title&facet=repositories&facet=scientific_field&facet=oa_host_type_year&facet=oa_host_type_year_scientific_field${redactionField}`
      )
      .then(response => {
        dataSearch = response.data;
      })
      .catch(error => {
        console.log(error);
        errored = true;
      })
      .finally(() => (loadingSearch = false));

    console.log(dataSearch);
    return { dataSearch, errored, loadingSearch };
  }
};
