import Vue from 'vue';
import Router from 'vue-router';
import VueGlobale from '@/views/VueGlobale';
import Error from '@/components/Error/Error.vue';

import SearchView from '@/views/SearchView.vue';

Vue.use(Router);
export default new Router({
  routes: [
    {
      path: '/',
      name: 'Vue-Globale',
      component: VueGlobale
    },
    {
      path: '/search',
      name: 'Search',
      component: SearchView
    },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ]
});
