<script>
import { Bar } from 'vue-chartjs';
import { LoadData } from '@/services/LoadData';
import Maps from '@/services/Maps';
export default {
  extends: Bar,
  props: ['activeChart', 'activeAllChart'],
  data() {
    return {
      loading: true,
      indexColumn: '',
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            borderWidth: 1,
            backgroundColor: '',
            borderColor: '',
            pointBorderColor: '',
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {},
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  methods: {
    reloadChartWithField() {
      {
        this.chartData.datasets[0].data = [];
        this.chartData.labels = [];
        this.chartData.datasets[0].borderColor = '';
        this.chartData.datasets[0].backgroundColor = '';
        this.chartData.datasets[0].pointBorderColor = '';
        this.chartData.datasets[0].label = `Nombres de publications dans  ${
          Maps.mapFieldBis[this.nameField]
        } par an`;

        LoadData.loadDataDashBoard(this.nameField).then(dataAfterSearch => {
          this.dataAfterSearch = dataAfterSearch.dataSearch;
          for (var y = 0; y < this.dataAfterSearch.facet_groups.length; y++) {
            if (this.dataAfterSearch.facet_groups[y].name == 'genre') {
              this.indexColumn = y;
              break;
            }
          }
          for (
            var i = 0;
            i <
            this.dataAfterSearch.facet_groups[this.indexColumn].facets.length;
            i++
          ) {
            this.chartData.datasets[0].data.push(
              this.dataAfterSearch.facet_groups[this.indexColumn].facets[i]
                .count
            );

            this.chartData.labels.push(
              Maps.mapGenderType[
                this.dataAfterSearch.facet_groups[this.indexColumn].facets[i]
                  .name
              ]
            );
            this.chartData.datasets[0].borderColor =
              Maps.mapFieldColor[this.nameField];
            this.chartData.datasets[0].pointBorderColor =
              Maps.mapFieldColor[this.nameField];

            this.chartData.datasets[0].backgroundColor =
              Maps.mapFieldColor[this.nameField];
          }
          if (window.innerWidth < 500) {
            (this.options.scales.yAxes[0].ticks = {
              fontSize: 7,
              fontFamily: "'Roboto', sans-serif",
              fontColor: '#000'
            }),
              (this.options.scales.xAxes[0].ticks = {
                fontSize: 7,
                fontFamily: "'Roboto', sans-serif",
                fontColor: '#000'
              });
            this.options.legend.labels = { fontSize: 7 };
          }
          this.loading = dataAfterSearch.loadingSearch;
          this.renderChart(this.chartData, this.options);
          if (!this.loading) {
            this.$emit('updateLoading', 'column');
          }
        });
      }
    },
    loadGlobalfield() {
      this.chartData.datasets[0].data = [];
      this.chartData.labels = [];
      this.chartData.datasets[0].borderColor = '';
      this.chartData.datasets[0].backgroundColor = '';
      this.chartData.datasets[0].pointBorderColor = 'rgb(23,158,184,0.7';
      this.chartData.datasets[0].label = `Type de documents comprenant les publications globales`;
      (this.chartData.datasets[0].borderColor = 'rgb(23,158,184,0.7'),
        (this.chartData.datasets[0].backgroundColor = 'rgb(23,158,184,0.7'),
        (this.chartData.datasets[0].pointBorderColor = 'rgb(23,158,184,0.7'),
        LoadData.loadDataGlobale().then(dataG => {
          for (var y = 0; y < dataG.data.facet_groups.length; y++) {
            if (dataG.data.facet_groups[y].name == 'genre') {
              this.indexColumn = y;
              break;
            }
          }
          for (var z = 0; z < 5; z++) {
            this.chartData.labels.push(
              Maps.mapGenderType[
                dataG.data.facet_groups[this.indexColumn].facets[z].name
              ]
            );
            (this.chartData.datasets[0].borderColor = 'rgb(23,158,184,0.7'),
              (this.chartData.datasets[0].backgroundColor =
                'rgb(23,158,184,0.7'),
              (this.chartData.datasets[0].pointBorderColor =
                'rgb(23,158,184,0.7'),
              this.chartData.datasets[0].data.push(
                dataG.data.facet_groups[this.indexColumn].facets[z].count
              );
          }
          if (window.innerWidth < 500) {
            (this.options.scales.yAxes[0].ticks = {
              fontSize: 10,
              fontFamily: "'Roboto', sans-serif",
              fontColor: '#000'
            }),
              (this.options.scales.xAxes[0].ticks = {
                fontSize: 10,
                fontFamily: "'Roboto', sans-serif",
                fontColor: '#000'
              });
            this.options.legend.labels = { fontSize: 7 };
          }
          this.loading = dataG.loadingG;
          this.renderChart(this.chartData, this.options);
          if (!this.loading) {
            this.$emit('updateLoading', 'column');
          }
        });
    }
  },
  computed: {
    nameField() {
      return this.$store.state.nameField;
    }
  },

  watch: {
    nameField(valeur) {
      this.loading = true;
      if (valeur) {
        this.reloadChartWithField();
      } else {
        this.loadGlobalfield();
      }
    }
  },
  mounted() {
    if (this.nameField) {
      this.reloadChartWithField();
    } else {
      this.loadGlobalfield();
    }
  }
};
</script>
