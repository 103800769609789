import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin], // activate plugin
  namespace: true,
  state: {
    darkMode: false,
    nameField: '',
    yearFilterActive: '',
    nameChartActiveField: '',
    wordSearch: '',
    genreFilterActive: ''
  },

  mutations: {
    loadSSwitchModeDark(state, darkMode) {
      state.darkMode = darkMode;
    },
    loadFieldName(state, nameField) {
      state.nameField = nameField;
    },
    loadWordSearch(state, wordSearch) {
      state.wordSearch = wordSearch;
    },
    loadyearFilterActive(state, value) {
      state.yearFilterActive = value;
    },

    loadGenreFilterActive(state, value) {
      state.genreFilterActive = value;
    },

    loadActifDashBoard(state, nameChartActiveField) {
      state.nameChartActiveField = nameChartActiveField;
    }
  },

  actions: {
    switchModeDark({ commit }, arrNameField) {
      commit('loadSSwitchModeDark', arrNameField);
    },
    addFieldName({ commit }, nameField) {
      commit('loadFieldName', nameField);
    },
    addwordSearch({ commit }, wordSearch) {
      commit('loadWordSearch', wordSearch);
    },
    addyearFilterActive({ commit }, value) {
      commit('loadyearFilterActive', value);
    },
    addGenreFilterActive({ commit }, value) {
      commit('loadGenreFilterActive', value);
    },
    addActifDashBoard({ commit }, nameChartActiveField) {
      commit('loadActifDashBoard', nameChartActiveField);
    }
  },
  modules: {}
});
