<script>
import { Pie } from 'vue-chartjs';
import { LoadData } from '@/services/LoadData';
import Maps from '@/services/Maps';
export default {
  props: ['activeChart', 'activeAllChart'],
  extends: Pie,
  data() {
    return {
      loading: true,
      indexPie: '',
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 0.1,
            hoverBorderColor: [],
            hoverBorderWidth: 10,

            backgroundColor: [],
            data: []
          }
        ]
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 20
          }
        },

        tooltips: {
          displayColors: true
        },
        legend: {
          display: true,
          labels: {}
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  methods: {
    reloadChartWithField() {
      if (window.innerWidth < 500) {
        this.options.legend.labels = { fontSize: 10 };
      }
      this.chartData.datasets[0].data = [];
      this.chartData.labels = [];
      this.chartData.datasets[0].hoverBorderColor = [];
      this.chartData.datasets[0].backgroundColor = [];
      LoadData.loadDataDashBoard(this.nameField).then((dataAfterSearch) => {
        this.dataAfterSearch = dataAfterSearch.dataSearch;
        this.chartData.datasets[0].hoverBorderColor.push(
          Maps.mapFieldColor[this.nameField]
        );
        this.chartData.datasets[0].backgroundColor.push(
          Maps.mapFieldColor[this.nameField]
        );

        for (
          var y = 0;
          y < this.dataAfterSearch.facet_groups[8].facets.length;
          y++
        ) {
          this.chartData.datasets[0].data.push(
            this.dataAfterSearch.facet_groups[8].facets[y].count
          );

          this.chartData.labels.push(
            Maps.mapGenderField[
              this.dataAfterSearch.facet_groups[8].facets[y].name
            ]
          );

          this.renderChart(this.chartData, this.options);
        }
        this.loading = dataAfterSearch.loadingSearch;
        if (!this.loading) {
          this.$emit('updateLoading', 'pie');
        }
      });
    },
    loadGlobalfield() {
      if (window.innerWidth < 500) {
        this.options.legend.labels = { fontSize: 7 };
      }
      this.chartData.datasets[0].data = [];
      this.chartData.labels = [];
      this.chartData.datasets[0].hoverBorderColor = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].hoverBorderColor.push(
        'rgb(150,66,167,0.7)',
        'rgb(114,178,234, 0.7)',
        'rgb(253,218,82, 0.7)',
        'rgb(124,184,68, 0.7)',
        'rgb(111,75,15, 0.7)',
        'rgb(67,63,101,0.7)',
        'rgb(43,133,182, 0.7)',
        'rgb(39,217,230, 0.7)',
        'rgb(233,130,5, 0.7)',
        'rgb(147,177,208, 0.7)',
        'rgb(251,130,92, 0.7)'
      );
      this.chartData.datasets[0].backgroundColor.push(
        'rgb(150,66,167,0.7)',
        'rgb(114,178,234, 0.7)',
        'rgb(253,218,82, 0.7)',
        'rgb(124,184,68, 0.7)',
        'rgb(111,75,15, 0.7)',
        'rgb(67,63,101,0.7)',
        'rgb(43,133,182, 0.7)',
        'rgb(39,217,230, 0.7)',
        'rgb(233,130,5, 0.7)',
        'rgb(147,177,208, 0.7)',
        'rgb(251,130,92, 0.7)'
      );
      LoadData.loadDataGlobale().then((dataG) => {
        for (var i = 0; i < dataG.data.facet_groups.length; i++) {
          if (dataG.data.facet_groups[i].name == 'scientific_field') {
            this.indexPie = i;
            break;
          }
        }
        if (window.innerWidth < 500) {
          this.options.legend.labels = { fontSize: 7 };
        }
        for (var y = 0; y < dataG.data.facet_groups[8].facets.length; y++) {
          this.chartData.datasets[0].data.push(
            dataG.data.facet_groups[8].facets[y].count
          );

          this.chartData.labels.push(
            Maps.mapGenderField[dataG.data.facet_groups[8].facets[y].name]
          );

          this.renderChart(this.chartData, this.options);
        }

        this.loading = dataG.loadingG;
        if (!this.loading) {
          this.$emit('updateLoading', 'pie');
        }
      });
    }
  },
  computed: {
    nameField() {
      return this.$store.state.nameField;
    }
  },

  watch: {
    nameField(valeur) {
      this.loading = true;
      if (valeur) {
        this.reloadChartWithField();
      } else {
        this.loadGlobalfield();
      }
    }
  },
  mounted() {
    if (this.nameField) {
      this.reloadChartWithField();
    } else {
      this.loadGlobalfield();
    }
  }
};
</script>
<style scoped>
@media (max-width: 1020px) {
  * {
    font-size: 2px !important;
  }
}
</style>
